@property --aegis-font {
		syntax: "<custom-ident> | <string>";
		inherits: true;
		initial-value: system-ui;
	}

@property --aegis-btn-border-radius {
		syntax: "<length>";
		inherits: true;
		initial-value: 5px;
	}

@property --aegis-color-light {
		syntax: "<color>";
		inherits: true;
		initial-value: #212529;
	}

@property --aegis-color-dark {
		syntax: "<color>";
		inherits: true;
		initial-value: #f8f9fa;
	}

@property --aegis-bg-light {
		syntax: "<color>";
		inherits: true;
		initial-value: #f8f9fa;
	}

@property --aegis-bg-dark {
		syntax: "<color>";
		inherits: true;
		initial-value: #212529;
	}

@property --aegis-border-color-light {
		syntax: "<color>";
		inherits: true;
		initial-value: #dee2e6;
	}

@property --aegis-border-color-dark {
		syntax: "<color>";
		inherits: true;
		initial-value: #495057;
	}

@property --aegis-border-radius {
		syntax: "<length>";
		inherits: true;
		initial-value: 8px;
	}

@property --aegis-btn-primary {
		syntax: "<color>";
		inherits: true;
		initial-value: #0d6efd;
	}

@property --aegis-btn-primary-hover {
		syntax: "<color>";
		inherits: true;
		initial-value: #0a58ca;
	}

@property --aegis-btn-primary-active {
		syntax: "<color>";
		inherits: true;
		initial-value: #084298;
	}

@property --aegis-btn-primary-disabled {
		syntax: "<color>";
		inherits: true;
		initial-value: #6c757d;
	}

@property --aegis-btn-secondary {
		syntax: "<color>";
		inherits: true;
		initial-value: #6c757d;
	}

@property --aegis-btn-secondary-hover {
		syntax: "<color>";
		inherits: true;
		initial-value: #495057;
	}

@property --aegis-btn-secondary-active {
		syntax: "<color>";
		inherits: true;
		initial-value: #343a40;
	}

@property --aegis-btn-success {
		syntax: "<color>";
		inherits: true;
		initial-value: #198754;
	}

@property --aegis-btn-success-hover {
		syntax: "<color>";
		inherits: true;
		initial-value: #146c43;
	}

@property --aegis-btn-success-active {
		syntax: "<color>";
		inherits: true;
		initial-value: #0f5132;
	}

@property --aegis-btn-success-disabled {
		syntax: "<color>";
		inherits: true;
		initial-value: #6c757d;
	}

@property --aegis-btn-info {
		syntax: "<color>";
		inherits: true;
		initial-value: #0dcaf0;
	}

@property --aegis-btn-info-hover {
		syntax: "<color>";
		inherits: true;
		initial-value: #087990;
	}

@property --aegis-btn-info-active {
		syntax: "<color>";
		inherits: true;
		initial-value: #0aa2c0;
	}

@property --aegis-btn-info-disabled {
		syntax: "<color>";
		inherits: true;
		initial-value: #6c757d;
	}

@property --aegis-btn-warning {
		syntax: "<color>";
		inherits: true;
		initial-value: #ffc107;
	}

@property --aegis-btn-warning-hover {
		syntax: "<color>";
		inherits: true;
		initial-value: #cc9a06;
	}

@property --aegis-btn-warning-active {
		syntax: "<color>";
		inherits: true;
		initial-value: #997404;
	}

@property --aegis-btn-warning-disabled {
		syntax: "<color>";
		inherits: true;
		initial-value: #6c757d;
	}

@property --aegis-btn-danger {
		syntax: "<color>";
		inherits: true;
		initial-value: #dc3545;
	}

@property --aegis-btn-danger-hover {
		syntax: "<color>";
		inherits: true;
		initial-value: #b02a37;
	}

@property --aegis-btn-danger-active {
		syntax: "<color>";
		inherits: true;
		initial-value: #842029;
	}

@property --aegis-btn-danger-disabled {
		syntax: "<color>";
		inherits: true;
		initial-value: #6c757d;
	}

@property --aegis-btn-dark {
		syntax: "<color>";
		inherits: true;
		initial-value: #212529;
	}

@property --aegis-btn-dark-hover {
		syntax: "<color>";
		inherits: true;
		initial-value: #495057;
	}

@property --aegis-btn-dark-active {
		syntax: "<color>";
		inherits: true;
		initial-value: #343a40;
	}

@property --aegis-btn-dark-disabled {
		syntax: "<color>";
		inherits: true;
		initial-value: #6c757d;
	}

@property --aegis-btn-light {
		syntax: "<color>";
		inherits: true;
		initial-value: #f8f9fa;
	}

@property --aegis-btn-light-hover {
		syntax: "<color>";
		inherits: true;
		initial-value: #e9ecef;
	}

@property --aegis-btn-light-active {
		syntax: "<color>";
		inherits: true;
		initial-value: #dee2e6;
	}

@property --aegis-btn-light-disabled {
		syntax: "<color>";
		inherits: true;
		initial-value: #6c757d;
	}

@property --aegis-btn-link {
		syntax: "<color>";
		inherits: true;
		initial-value: transparent;
	}

@property --aegis-btn-link-hover {
		syntax: "<color>";
		inherits: true;
		initial-value: transparent;
	}

@property --aegis-btn-link-active {
		syntax: "<color>";
		inherits: true;
		initial-value: transparent;
	}

@property --aegis-btn-link-disabled {
		syntax: "<color>";
		inherits: true;
		initial-value: #6c757d;
	}

@property --aegis-link-color {
		syntax: "<color>";
		inherits: true;
		initial-value: LinkText;
	}

@property --aegis-scrollbar-color {
		syntax: "<color>";
		inherits: true;
		initial-value: #0d6efd;
	}

@property --aegis-scrollbar-track {
		syntax: "<color>";
		inherits: true;
		initial-value: #212529;
	}

@property --aegis-scrollbar-width {
		syntax: "<custom-ident>";
		inherits: true;
		initial-value: auto;
	}

@property --aegis-blink-speed {
		syntax: "<time>";
		inherits: true;
		initial-value: 200ms;
	}

@property --aegis-fast-speed {
		syntax: "<time>";
		inherits: true;
		initial-value: 400ms;
	}

@property --aegis-normal-speed {
		syntax: "<time>";
		inherits: true;
		initial-value: 600ms;
	}

@property --aegis-slow-speed {
		syntax: "<time>";
		inherits: true;
		initial-value: 800ms;
	}

@property --aegis-crawl-speed {
		syntax: "<time>";
		inherits: true;
		initial-value: 1200ms;
	}

@property --aegis-btn-disabled-opacity {
		syntax: "<number>";
		inherits: true;
		initial-value: 0.65;
	}

@property --aegis-btn-disabled-saturation {
		syntax: "<percentage>";
		inherits: true;
		initial-value: 50%;
	}

@property --aegis-btn-disabled-brightness {
		syntax: "<percentage>";
		inherits: true;
		initial-value: 90%;
	}

:root {
	--aegis-font: system-ui;

--aegis-btn-border-radius: 5px;

--aegis-color-light: #212529;

--aegis-color-dark: #f8f9fa;

--aegis-bg-light: #f8f9fa;

--aegis-bg-dark: #212529;

--aegis-border-color-light: #dee2e6;

--aegis-border-color-dark: #495057;

--aegis-border-radius: 8px;

--aegis-btn-primary: #0d6efd;

--aegis-btn-primary-hover: #0a58ca;

--aegis-btn-primary-active: #084298;

--aegis-btn-primary-disabled: #6c757d;

--aegis-btn-secondary: #6c757d;

--aegis-btn-secondary-hover: #495057;

--aegis-btn-secondary-active: #343a40;

--aegis-btn-success: #198754;

--aegis-btn-success-hover: #146c43;

--aegis-btn-success-active: #0f5132;

--aegis-btn-success-disabled: #6c757d;

--aegis-btn-info: #0dcaf0;

--aegis-btn-info-hover: #087990;

--aegis-btn-info-active: #0aa2c0;

--aegis-btn-info-disabled: #6c757d;

--aegis-btn-warning: #ffc107;

--aegis-btn-warning-hover: #cc9a06;

--aegis-btn-warning-active: #997404;

--aegis-btn-warning-disabled: #6c757d;

--aegis-btn-danger: #dc3545;

--aegis-btn-danger-hover: #b02a37;

--aegis-btn-danger-active: #842029;

--aegis-btn-danger-disabled: #6c757d;

--aegis-btn-dark: #212529;

--aegis-btn-dark-hover: #495057;

--aegis-btn-dark-active: #343a40;

--aegis-btn-dark-disabled: #6c757d;

--aegis-btn-light: #f8f9fa;

--aegis-btn-light-hover: #e9ecef;

--aegis-btn-light-active: #dee2e6;

--aegis-btn-light-disabled: #6c757d;

--aegis-btn-link: transparent;

--aegis-btn-link-hover: transparent;

--aegis-btn-link-active: transparent;

--aegis-btn-link-disabled: #6c757d;

--aegis-link-color: LinkText;

--aegis-scrollbar-color: #0d6efd;

--aegis-scrollbar-track: #212529;

--aegis-scrollbar-width: auto;

--aegis-blink-speed: 200ms;

--aegis-fast-speed: 400ms;

--aegis-normal-speed: 600ms;

--aegis-slow-speed: 800ms;

--aegis-crawl-speed: 1200ms;

--aegis-btn-disabled-opacity: 0.65;

--aegis-btn-disabled-saturation: 50%;

--aegis-btn-disabled-brightness: 90%;

}