.btn:not([hidden]) {
	cursor: pointer;
	display: inline-block;
	padding: 0.6em 1.3em;
	border-width: 1px;
	border-style: solid;
	appearance: button;
	line-height: 1.5;
	text-align: center;
	vertical-align: middle;
	font-family: inherit;
	font-size: 1rem;
	border-radius: var(--aegis-btn-border-radius, 5px);
	text-decoration: none;
	transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, color 150ms ease-in-out;
}

.btn.btn-sm {
	padding: 0.5em 1em;
	font-size: 0.7rem;
}

.btn.btn-lg {
	padding: 0.5em 1.1em;
	font-size: 1.3rem;
}

.btn.btn-block {
	display: block;
	width: 100%;
}

.btn:disabled:not(.btn-system, .btn-system-accent), .btn.disabled:not(.btn-system, .btn-system-accent), .btn:disabled, .disabled, :state(--disabled) {
	cursor: not-allowed;
	opacity: var(--aegis-btn-disabled-opacity, 0.65);
	filter: saturate(var(--aegis-btn-disabled-saturation, 50%)) brightness(var(--aegis-btn-disabled-brightness, 90%));
}

.btn.disabled, .btn:state(--disabled) {
	pointer-events: none;
}

.btn:focus-visible {
	outline: 2px solid;
	outline-offset: 2px;
}

.btn.btn-danger {
	background-color: var(--aegis-btn-danger, #dc3545);
	color: var(--aegis-color-dark, #f8f9fa);
	border-color: #842029;
}

.btn.btn-danger:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-danger:focus-visible {
	background-color: var(--aegis-btn-danger-hover, #b02a37);
	border-color: #58151c;
}

.btn.btn-danger:focus-visible {
	outline-color: var(--aegis-btn-danger, #dc3545);
}

.btn.btn-danger:active {
	background-color: var(--aegis-btn-danger-active, #842029);
	border-color: #2c0b0e;
}

.btn.btn-dark {
	background-color: var(--aegis-btn-dark, #212529);
	color: var(--aegis-color-dark, #f8f9fa);
	border-color: #343a40;
}

.btn.btn-dark:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-dark:focus-visible {
	background-color: var(--aegis-btn-dark-hover, #495057);
	border-color: #212529;
}

.btn.btn-dark:focus-visible {
	outline-color: var(--aegis-btn-dark, #212529);
}

.btn.btn-dark:active {
	background-color: var(--aegis-btn-dark-active, #343a40);
	border-color: undefined;
}

.btn.btn-info {
	background-color: var(--aegis-btn-info, #0dcaf0);
	color: var(--aegis-color-dark, #f8f9fa);
	border-color: #087990;
}

.btn.btn-info:not(:disabled, .disabled, :state(--disabled)), .btn.btn-info:focus-visible {
	background-color: var(--aegis-btn-info-hover, #087990);
	border-color: #055160;
}

.btn.btn-info:focus-visible {
	outline-color: var(--aegis-btn-info, #0dcaf0);
}

.btn.btn-info:active {
	background-color: var(--aegis-btn-info-active, #0aa2c0);
	border-color: #032830;
}

.btn.btn-light {
	background-color: var(--aegis-btn-light, #f8f9fa);
	color: var(--aegis-color-light, #212529);
	border-color: #dee2e6;
}

.btn.btn-light:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-light:focus-visible {
	background-color: var(--aegis-btn-light-hover, #e9ecef);
	border-color: #ced4da;
}

.btn.btn-light:focus-visible {
	outline-color: var(--aegis-btn-light, #f8f9fa);
}

.btn.btn-light:active {
	background-color: var(--aegis-btn-light-active, #dee2e6);
	border-color: #adb5bd;
}

.btn.btn-link {
	background-color: var(--aegis-btn-link, transparent);
	color: LinkText;
	border-color: transparent;
	text-decoration: none;
}

.btn.btn-link:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-link:focus-visible {
	background-color: var(--aegis-btn-link-hover, transparent);
	text-decoration: underline;
}

.btn.btn-link:focus-visible {
	outline-color: var(--aegis-btn-link, transparent);
}

.btn.btn-link:active {
	background-color: var(--aegis-btn-link-active, transparent);
	text-decoration: underline;
}

.btn.btn-outline-danger {
	background-color: transparent;
	color: var(--aegis-btn-danger, #dc3545);
	border-color: currentColor;
}

.btn.btn-outline-danger:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-outline-danger:focus-visible {
	background-color: var(--aegis-btn-danger-hover, #b02a37);
	border-color: #58151c;
	color: var(--aegis-color-dark, #f8f9fa);
}

.btn.btn-outline-danger:focus-visible {
	outline-color: var(--aegis-btn-danger, #dc3545);
}

.btn.btn-outline-danger:active {
	background-color: var(--aegis-btn-danger-active, #842029);
	border-color: #2c0b0e;
	color: var(--aegis-color-dark, #f8f9fa);
}

.btn.btn-outline-dark {
	background-color: transparent;
	color: var(--aegis-btn-dar, #212529);
	border-color: currentColor;
}

.btn.btn-outline-dark:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-outline-dark:focus-visible {
	background-color: var(--aegis-btn-dark-hover, #495057);
	border-color: #212529;
	color: var(--aegis-color-dark, #f8f9fa);
}

.btn.btn-outline-dark:focus-visible {
	outline-color: var(--aegis-btn-dark, #212529);
}

.btn.btn-outline-dark:active {
	background-color: var(--aegis-btn-dark-active, #343a40);
	border-color: undefined;
	color: var(--aegis-color-dark, #f8f9fa);
}

.btn.btn-outline-secondary:disabled, .btn.btn-outline-secondary.disabled, .btn.btn-outline-secondary:state(--disabled) {
	color: var(--aegis-btn-active-disabled, #6c757d);
}

.btn.btn-outline-info {
	background-color: transparent;
	color: var(--aegis-btn-info, #0dcaf0);
	border-color: currentColor;
}

.btn.btn-outline-info:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-outline-info:focus-visible {
	background-color: var(--aegis-btn-info-hover, #087990);
	border-color: #055160;
	color: var(--aegis-color-dark, #f8f9fa);
}

.btn.btn-outline-info:focus-visible {
	outline-color: var(--aegis-btn-info, #0dcaf0);
}

.btn.btn-outline-info:active {
	background-color: var(--aegis-btn-info-active, #0aa2c0);
	border-color: #032830;
	color: var(--aegis-color-dark, #f8f9fa);
}

.btn.btn-outline-light {
	background-color: transparent;
	color: var(--aegis-btn-light, #f8f9fa);
	border-color: currentColor;
}

.btn.btn-outline-light:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-outline-light:focus-visible {
	background-color: var(--aegis-btn-light-hover, #e9ecef);
	border-color: #ced4da;
	color: var(--aegis-color-light, #212529);
}

.btn.btn-outline-light:focus-visible {
	outline-color: var(--aegis-btn-light, #f8f9fa);
}

.btn.btn-outline-light:active {
	background-color: var(--aegis-btn-light-active, #dee2e6);
	border-color: #adb5bd;
	color: var(--aegis-btn-light-active, #dee2e6);
}

.btn.btn-outline-primary {
	background-color: transparent;
	color: var(--aegis-btn-primary, #0d6efd);
	border-color: currentColor;
}

.btn.btn-outline-primary:focus-visible {
	outline-color: var(--aegis-btn-primary, #0d6efd);
}

.btn.btn-outline-primary:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-outline-primary:focus-visible {
	background-color: var(--aegis-btn-primary-hover, #0a58ca);
	border-color: #052c65;
	color: var(--aegis-color-dark, #f8f9fa);
}

.btn.btn-outline-primary:active {
	background-color: var(--aegis-btn-primary-active, #084298);
	border-color: #031633;
	color: var(--aegis-color-dark, #f8f9fa);
}

.btn.btn-outline-secondary {
	background-color: transparent;
	color: var(--aegis-btn-secondary, #6c757d);
	border-color: currentColor;
}

.btn.btn-outline-secondary:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-outline-secondary:focus-visible {
	background-color: var(--aegis-btn-secondary-hover, #495057);
	border-color: #212529;
	color: var(--aegis-color-dark, #f8f9fa);
}

.btn.btn-outline-secondary:focus-visible {
	outline-color: var(--aegis-btn-secondary, #6c757d);
}

.btn.btn-outline-secondary:active {
	background-color: var(--aegis-btn-secondary-active, #343a40);
	border-color: undefined;
	color: var(--aegis-color-dark, #f8f9fa);
}

.btn.btn-outline-secondary:disabled, .btn.btn-outline-secondary.disabled, .btn.btn-outline-seconday:state(--disabled) {
	color: var(--aegis-btn-active-disabled, #6c757d);
}

.btn.btn-outline-success {
	background-color: transparent;
	color: var(--aegis-btn-success, #198754);
	border-color: currentColor;
}

.btn.btn-outline-success:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-outline-success:focus-visible {
	background-color: var(--aegis-btn-success-hover, #146c43);
	border-color: #0a3622;
	color: var(--aegis-color-dark, #f8f9fa);
}

.btn.btn-outline-success:focus-visible {
	outline-color: var(--aegis-btn-success, #198754);
}

.btn.btn-outline-success:active {
	background-color: var(--aegis-btn-success-active, #0f5132);
	border-color: #051b11;
	color: var(--aegis-color-dark, #f8f9fa);
}

.btn.btn-outline-warning {
	background-color: transparent;
	color: var(--aegis-btn-warning, #ffc107);
	border-color: currentColor;
}

.btn.btn-outline-warning:not(:disabled, .disabled, :state(--disabled)), .btn.btn-outline-warning:focus-visible {
	background-color: var(--aegis-btn-warning-hover, #cc9a06);
	border-color: #664d03;
	color: var(--aegis-color-light, #212529);
}

.btn.btn-outline-warning:active {
	background-color: var(--aegis-btn-warning-active, #997404);
	border-color: #332701;
	color: var(--aegis-color-light, #212529);
}

.btn.btn-outline-warning:focus-visible {
	outline-color: var(--aegis-btn-warning, #ffc107);
}

.btn.btn-primary {
	background-color: var(--aegis-btn-primary, #0d6efd);
	color: var(--aegis-color-dark, #f8f9fa);
	border-color: #084298;
}

.btn.btn-primary:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-primary:focus-visible {
	background-color: var(--aegis-btn-primary-hover, #0a58ca);
	border-color: #052c65;
}

.btn.btn-primary:focus-visible {
	outline-color: var(--aegis-btn-primary, #0d6efd);
}

.btn.btn-primary:active {
	background-color: var(--aegis-btn-primary-active, #084298);
	border-color: #031633;
}

.btn.btn-secondary {
	background-color: var(--aegis-btn-secondary, #6c757d);
	color: var(--aegis-color-dark, #f8f9fa);
	border-color: #343a40;
}

.btn.btn-secondary:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-secondary:focus-visible {
	background-color: var(--aegis-btn-secondary-hover, #495057);
	border-color: #212529;
}

.btn.btn-secondary:focus-visible {
	outline-color: var(--aegis-btn-secondary, #6c757d);
}

.btn.btn-secondary:active {
	background-color: var(--aegis-btn-secondary-active, #343a40);
	border-color: undefined;
}

.btn.btn-success {
	background-color: var(--aegis-btn-success, #198754);
	color: var(--aegis-color-dark, #f8f9fa);
	border-color: #0f5132;
}

.btn.btn-success:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-success:focus-visible {
	background-color: var(--aegis-btn-success-hover, #146c43);
	border-color: #0a3622;
}

.btn.btn-success:focus-visible {
	outline-color: var(--aegis-btn-success, #198754);
}

.btn.btn-success:active {
	background-color: var(--aegis-btn-success-active, #0f5132);
	border-color: #051b11;
}

.btn.btn-system {
	background-color: ButtonFace;
	color: ButtonText;
	border-color: hsl(from ButtonFace h s calc(l - 0.2));
}

.btn.btn-system:hover {
	background-color: hsl(from ButtonFace h s calc(l * 1.1));
	border-color: hsl(from ButtonFace h s calc(l * 1.1));
}

.btn.btn-system:focus-visible {
	background-color: hsl(from ButtonFace h calc(s * 1.05) calc(l * 1.05));
	border-color: hsl(from ButtonFace h calc(s * 1.05) calc(l * 1.05));
	outline: 2px solid ButtonText;
	outline-offset: 2px;
}

.btn.btn-system:active {
	background-color: hsl(from ButtonFace h s calc(l * 0.8));
	border-color: hsl(from ButtonFace h s calc(l * 0.8));
}

.btn.btn-system:disabled, .btn.btn-system.disabled, .btn.btn-system:state(--disabled) {
	background-color: hsl(from ButtonFace h calc(s * 0.2) l);
	border-color: hsl(from ButtonFace h calc(s * 0.2) l);
	color: GrayText;
}

.btn.btn-system-accent {
	background-color: AccentColor;
	color: AccentColorText;
	border-color: hsl(from AccentColor h s calc(l - 0.2));
}

.btn.btn-system-accent:hover {
	background-color: hsl(from AccentColor h s calc(l * 1.1));
	border-color: hsl(from AccentColor h s calc(l * 1.1));
}

.btn.btn-system-accent:focus-visible {
	background-color: hsl(from AccentColor h calc(s * 1.05) calc(l * 1.05));
	border-color: hsl(from AccentColor h calc(s * 1.05) calc(l * 1.05));
	outline: 2px solid hsl(from AccentColor h s calc(l - 0.3));
	outline-offset: 2px;
}

.btn.btn-system-accent:active {
	background-color: hsl(from AccentColor h s calc(l * 0.8));
	border-color: hsl(from AccentColor h s calc(l * 0.8));
}

.btn.btn-system-accent:disabled, .btn.btn-system-accent.disabled, .btn.btn-system:state(--disabled) {
	background-color: hsl(from AccentColor h calc(s * 0.2) l);
	border-color: hsl(from AccentColor h calc(s * 0.2) l);
	color: GrayText;
}

.btn.btn-warning {
	background-color: var(--aegis-btn-warning, #ffc107);
	color: var(--aegis-color-light, #212529);
	border-color: #997404;
}

.btn.btn-warning:hover:not(:disabled, .disabled, :state(--disabled)), .btn.btn-warning:focus-visible {
	background-color: var(--aegis-btn-warning-hover, #cc9a06);
	border-color: #664d03;
}

.btn.btn-warning:focus-visible {
	outline-color: var(--aegis-btn-warning, #ffc107);
}

.btn.btn-warning:active {
	background-color: var(--aegis-btn-warning-active, #997404);
	border-color: #332701;
}