/**
 * From Colour Palette <https://gitlab.gnome.org/World/design/palette>
*/
:root {
	--gnome-blue-1: #99c1f1;
	--gnome-blue-2: #62a0ea;
	--gnome-blue-3: #3584e4;
	--gnome-blue-4: #1c71d8;
	--gnome-blue-5: #1a5fb4;

	--gnome-green-1: #8ff0a4;
	--gnome-green-2: #57e389;
	--gnome-green-3: #33d17a;
	--gnome-green-4: #2ec27e;
	--gnome-green-5: #26a269;

	--gnome-yellow-1: #f9f06b;
	--gnome-yellow-2: #f8e45c;
	--gnome-yellow-3: #f6d32d;
	--gnome-yellow-4: #f5c211;
	--gnome-yellow-5: #e5a50a;

	--gnome-orange-1: #ffbe6f;
	--gnome-orange-2: #ffa348;
	--gnome-orange-3: #ff7800;
	--gnome-orange-4: #e66100;
	--gnome-orange-5: #c64600;

	--gnome-red-1: #f66151;
	--gnome-red-2: #ed333b;
	--gnome-red-3: #e01b24;
	--gnome-red-4: #c01c28;
	--gnome-red-5: #a51d2d;

	--gnome-purple-1: #dc8add;
	--gnome-purple-2: #c061cb;
	--gnome-purple-3: #9141ac;
	--gnome-purple-4: #813d9c;
	--gnome-purple-5: #613583;

	--gnome-brown-1: #cdab8f;
	--gnome-brown-2: #b5835a;
	--gnome-brown-3: #986a44;
	--gnome-brown-4: #865e3c;
	--gnome-brown-5: #63452c;

	--gnome-light-1: #ffffff;
	--gnome-light-2: #f6f5f4;
	--gnome-light-3: #deddda;
	--gnome-light-4: #c0bfbc;
	--gnome-light-5: #9a9996;

	--gnome-dark-1: #77767b;
	--gnome-dark-2: #5e5c64;
	--gnome-dark-3: #3d3846;
	--gnome-dark-4: #241f31;
	--gnome-dark-5: #000000;
}
