.multi-step-form {
	overflow: hidden;
	display: flex;
	flex-direction: row;

	& .form-section {
		flex-basis: 100%;
		flex-shrink: 0;
	}
}
