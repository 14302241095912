#nav {
	font-size: 1.2em;
	gap: 18px;
	padding: 0 0.8rem;
	background-color: var(--gnome-dark-4);
	color: var(--gnome-light-2);

	@media (max-width: 600px) {
		& .nav-link-label {
			display: none;
		}
	}
}

#nav > .nav-btn {
	background-color: transparent;
	color: inherit;
	border: none;
}

#install-btn > svg {
	margin-bottom: 10px;
}
